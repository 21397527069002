const roundingRules: Record<string, (n: number) => number> = {
  default: (num: number) => Math.ceil(num),
  EUR: (num: number) => {
    return Math.round(num) - 0.05;
  },
};

const CURRENCY_CONVERSION_FEE = 1.0175;

const formatters: Record<string, (n: number) => string> = {
  default: (num: number) => `$${num.toFixed(2)}`,
  GBP: (num: number) => `£${num.toFixed(2)}`,
  EUR: (num: number) => `€${num.toFixed(2)}`,
};

const FROM_CURRENCY = 'GBP';

export const currencyConverter = {
  convert(fromAmount: number, toCurrency: string): number {
    if (toCurrency === FROM_CURRENCY) return fromAmount;
    // eslint-disable-next-line no-undef
    const initial = (window as any).Currency.convert(
      fromAmount,
      FROM_CURRENCY,
      toCurrency,
    ) as number;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(initial)) return this.convert(fromAmount, 'USD');
    const rule = roundingRules[toCurrency] || roundingRules.default;
    return rule(initial * CURRENCY_CONVERSION_FEE);
  },
  format(amount: number, toCurrency: string) {
    const formatter = formatters[toCurrency] || formatters.default;
    return formatter(amount);
  },
  convertWithFormat(fromAmount: number, toCurrency: string) {
    return this.format(this.convert(fromAmount, toCurrency), toCurrency);
  },
};
