import React from 'react';
import {ScreenSizing} from '../../../types';
import {FRAME_OPTIONS} from '../../../constants';
import {
  FrameContainer,
  TopRightLine,
  TopLeftLine,
  BottomLeftLine,
  BottomRightLine,
  InnerShadow,
} from './frame.styles';

interface Props {
  children: React.ReactNode;
  frame: typeof FRAME_OPTIONS[number];
  size: {height: number; width: number};
  screenSizing: ScreenSizing;
  active: boolean;
}

const Frame = (props: Props) => {
  const active = props.active && props.frame.price;
  const MARGIN = active ? 38 : 0;
  const RATIO = 0.07;
  const scaledRatio = RATIO / 2 + RATIO * (30 / props.size.width);
  const width = props.screenSizing.isMobile
    ? props.screenSizing.innerWidth
    : props.screenSizing.maxWidth;
  const height = props.screenSizing.maxHeight;

  let frameInnerWidth = (width - 2 * MARGIN) / 1 + 2 * scaledRatio;
  let frameWidth = active ? ((width - 2 * MARGIN) * scaledRatio) / 2 : 0;

  let frameInnerHeight =
    (frameInnerWidth - 2 * frameWidth) *
      (props.size.height / props.size.width) +
    2 * frameWidth;

  if (frameInnerHeight > height - 2 * MARGIN) {
    frameInnerHeight = (height - 2 * MARGIN) / 1 + 2 * scaledRatio;
    frameWidth = active ? ((height - 2 * MARGIN) * scaledRatio) / 2 : 0;
    frameInnerWidth =
      (frameInnerHeight - 2 * frameWidth) *
        (props.size.width / props.size.height) +
      2 * frameWidth;
  }

  return (
    <FrameContainer active={!!active}>
      <InnerShadow
        width={frameInnerWidth}
        height={frameInnerHeight}
        color={props.frame.color}
        active={!!active}
        padding={active ? frameWidth : 0}
      >
        {props.children}
        <TopRightLine frameWidth={frameWidth} />
        <TopLeftLine frameWidth={frameWidth} />
        <BottomLeftLine frameWidth={frameWidth} />
        <BottomRightLine frameWidth={frameWidth} />
      </InnerShadow>
    </FrameContainer>
  );
};

export {Frame};
