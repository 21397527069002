import React from 'react';
import {LoaderContainer, StyledLoader} from './loader.styles';

export const Loader = () => {
  return (
    <LoaderContainer>
      <StyledLoader />
    </LoaderContainer>
  );
};
