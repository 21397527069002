"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ImageStyle;

(function (ImageStyle) {
  ImageStyle["Portrait"] = "Portrait";
  ImageStyle["Square"] = "Square";
})(ImageStyle = exports.ImageStyle || (exports.ImageStyle = {}));