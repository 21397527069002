import React from 'react';
import ReactToggle from 'react-toggle';
import {ToggleContainer} from './toggle.styles';
import {StandardText} from '../styled/standard-text';

interface Props {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  name: string;
  onText?: string;
  offText?: string;
}

const Toggle = ({checked, onChange, text, name}: Props) => (
  <ToggleContainer>
    <StandardText>{text}</StandardText>
    <ReactToggle
      name={name}
      checked={checked}
      onChange={onChange}
      icons={false}
    />
  </ToggleContainer>
);

export {Toggle};
