import styled, {createGlobalStyle} from 'styled-components';
import {theme} from './theme';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'News Cycle', sans-serif;
    background-color: ${theme.colors.blackBlue};
  }

  button {
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const PreloadAkwproscFont = styled.div`
  font-family: 'akweprosc';
`;

export const PreloadPoppinsFont = styled.div`
  font-family: 'poppins';
`;
