import React, {useRef, useEffect, useState} from 'react';
import {writeImageToCanvas} from '@pit/image-generator';
import {PlanetsContainer, CanvasContainer, Canvas} from './planets.styles';
import {useFormStateContext, Properties} from '../form/state';
import {ScreenSizing} from '../../types';
import {Loader} from '../common/loader/loader.ui';
import {Frame} from '../common/frame';

/**
 * TODO: This is not an ideal solution.
 * However this solves a problem where resizing the screen quickly will render
 * mutiple canvases and with in correct sizing.
 */
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
};

const Planets = ({
  screenSizing,
  isMobile,
}: {
  screenSizing: ScreenSizing;
  toggleReview: Function;
  isMobile: boolean;
}) => {
  const [toggleLoader, setToggleLoader] = useState(true);
  const form = useFormStateContext();
  const myCanvasRef = useRef<HTMLCanvasElement>(null);
  const delayedScreenSize = useDebounce(screenSizing, 500);

  useEffect(() => {
    const date = form.values[Properties.SpecialDate];
    const setCanvas = async () => {
      if (myCanvasRef.current && date) {
        await writeImageToCanvas(
          {
            date: date.toString(),
            canvas: myCanvasRef.current as any,
            includePluto: !form.values[Properties.HidePluto],
            lineOne: form.values[Properties.LineOne],
            lineTwo: form.values[Properties.LineTwo],
            dateFormat: form.values[Properties.DateStyle],
            showOrbitLines: form.values[Properties.OrbitLines],
            dimensions: form.values[Properties.Size].dimensions,
            orientation: form.values[Properties.Size].orientation,
            font: 'akweprosc',
            shouldIncludeWatermark: true,
            shouldAnimate: !toggleLoader,
          },
          toggleLoader ? undefined : 0,
        );
        if (toggleLoader) setToggleLoader(false);
      } else {
        setToggleLoader(true);
      }
    };
    setCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values, delayedScreenSize, myCanvasRef.current]);

  return (
    <PlanetsContainer isMobile={isMobile}>
      <CanvasContainer isMobile={isMobile} display={!toggleLoader}>
        <Frame
          screenSizing={screenSizing}
          size={form.values[Properties.Size].dimensions}
          frame={form.values[Properties.Frame]}
          active={!toggleLoader}
        >
          {toggleLoader && <Loader />}
          <Canvas
            onContextMenu={(e) => e.preventDefault()}
            ref={myCanvasRef}
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
            }}
          />
          {/* Leave here for test purposes. */}
          {/* <img
          style={{
            opacity: 0.3,
            position: "absolute",
            height: screenSizing.canvasSize,
            width: screenSizing.canvasSize
          }}
          src={require("../../manually-generated-image-for-comparison.jpg")}
        /> */}
        </Frame>
      </CanvasContainer>
    </PlanetsContainer>
  );
};

export {Planets};
