import {Icon} from '@chakra-ui/core';
import React, {useState, useContext, useEffect, useMemo} from 'react';
import {AccordionContext} from '../../form/form.ui';
import {
  DropDownContainer,
  Input,
  InputContainer,
  ChevronContainer,
  Option,
  OptionText,
  OptionContainer,
} from './dropdown.styles';

interface Props {
  options: Array<{label: string | number; value: string | number}>;
  placeholder: string;
  searchable?: boolean;
  value: number | string | null;
  onChange: (
    value: number | string | null,
    option?: {label: string | number; value: string | number},
  ) => void;
  type: 'string' | 'number';
  initialValue?: string | number;
}

const DropDown = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const {openAccordionId} = useContext(AccordionContext);

  useEffect(() => {
    if (!openAccordionId && open) {
      setOpen(false);
    }
  }, [openAccordionId, open]);

  const label = useMemo(
    () => props.options.find((option) => option.value === props.value),
    [props.options, props.value],
  );

  return (
    <DropDownContainer>
      <InputContainer onClick={() => setOpen(!open)}>
        {props.searchable && open ? (
          <Input
            type={props.type}
            disabled={!props.searchable}
            onKeyPress={
              props.searchable
                ? (e) => {
                    if (
                      (e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57
                    ) {
                      e.preventDefault();
                    }
                  }
                : undefined
            }
            onChange={
              props.searchable
                ? (e) => {
                    if (typeof parseInt(e.target.value, 10) === 'number') {
                      setHasChanged(true);
                      props.onChange(e.target.value);
                    }
                  }
                : undefined
            }
            onBlur={() => {
              setTimeout(() => setOpen(false), 200);
            }}
            placeholder={props.placeholder}
            value={
              (!hasChanged && props.initialValue) ||
              (label && label.label) ||
              ''
            }
          />
        ) : (
          <OptionText>
            {(!hasChanged && props.initialValue) ||
              (label && label.label) ||
              props.placeholder ||
              ''}
          </OptionText>
        )}

        <ChevronContainer rotate={open}>
          <Icon size="16px" name="chevron-up" color="white" />
        </ChevronContainer>
      </InputContainer>
      {open && (
        <OptionContainer>
          {props.options.map((option) => (
            <Option
              onClick={(e) => {
                props.onChange(option.value, option);
                setHasChanged(true);

                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
              key={option.value}
            >
              <OptionText>{option.label}</OptionText>
            </Option>
          ))}
        </OptionContainer>
      )}
    </DropDownContainer>
  );
};

export {DropDown};
