import {theme as chakraTheme} from '@chakra-ui/core';

const TRANSITION_DURATION = 200;

const theme = {
  ...chakraTheme,
  colors: {
    ...chakraTheme.colors,
    buttonBlue: '#2A6BB8',
    blackBlue: '#060611',
    imageBackground: '#070725',
    darkBlue: '#0F1124',
    white: '#FFFFFF',
    backgroundTwo: '#2B2D3E',
    backgroundThree: '#E1E1ED',
    inactive: 'rgba(127, 132, 141, 0.2)',
    accordion: '#0F1124',
    natural: '#E2D2BE',
  },
  transitions: `all ${TRANSITION_DURATION}ms ease`,
};

export {theme};
