import styled from 'styled-components';
import {theme} from '../../../theme';

// eslint-disable-next-line
export const ImageContainer = styled.div<{
  height: number;
  width: number;
  selected: boolean;
}>`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 100%;
  padding-bottom: ${(props) => (props.height / props.width) * 100}%;
  background-color: ${(props) =>
    props.selected ? theme.colors.buttonBlue : theme.colors.inactive};
  margin-bottom: 8px;
  position: relative;
  &:hover {
    background-color: ${theme.colors.buttonBlue};
  }

  &:active {
    opacity: 0.8;
  }
`;

// eslint-disable-next-line
export const Container = styled.div<{
  height: number;
  width: number;
  noMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: ${(props) => (props.noMargin ? 0 : 16)}px;
  width: calc(
    ${(props) => (props.width / 330) * 100}% -
      ${(props) => (props.noMargin ? 0 : 16)}px
  );
  cursor: pointer;
`;

export const Price = styled.p`
  color: white;
  font-size: 12px;
  font-family: poppins;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0;
  font-size: 12px;
`;
