import React from 'react';
import {SIZE_OPTIONS} from '../../../constants';
import {StandardText} from '../styled/standard-text';
import {Container, ImageContainer, Price} from './image-size.styles';

interface Props {
  dimensions: {
    height: number;
    width: number;
  };
  dimensionsInches: {
    height: number;
    width: number;
  };
  price: string;
  noMargin?: boolean;
  onSelect: () => void;
  selected: boolean;
}

const SCALE_FACTOR = 2;

const scaleDimensions = (dimensions: Props['dimensions']) => {
  const [totalHeight, totalWidth] = SIZE_OPTIONS.reduce(
    (acc, option) => [
      acc[0] + option.dimensions.height,
      acc[1] + option.dimensions.width,
    ],
    [0, 0],
  );
  const aveHeight = totalHeight / SIZE_OPTIONS.length;
  const aveWidth = totalWidth / SIZE_OPTIONS.length;
  const height = aveHeight + (dimensions.height - aveHeight / 2);
  const width = aveWidth + (dimensions.width - aveWidth / 2);
  return {
    height: height * SCALE_FACTOR,
    width: width * SCALE_FACTOR,
  };
};

const ImageSize = ({
  dimensions,
  dimensionsInches,
  price,
  noMargin,
  selected,
  onSelect,
}: Props) => (
  <Container noMargin={noMargin} {...scaleDimensions(dimensions)}>
    <ImageContainer
      onClick={onSelect}
      selected={selected}
      {...scaleDimensions(dimensions)}
    >
      <Price>{price}</Price>
    </ImageContainer>
    <StandardText style={{textAlign: 'center'}} fontSize={12} opacity={1}>
      {`${dimensionsInches.width}x${dimensionsInches.height}”`}
      <br />
      {`(${dimensions.width}x${dimensions.height}cm)`}
    </StandardText>
  </Container>
);

export {ImageSize};
