import styled from 'styled-components';

export const InputContainer = styled.div`
  padding-top: 24px;
`;

interface InputProps {
  valid: boolean;
  value: string;
  validated: boolean;
}

export const StyledInput = styled.input`
  width: calc(100% - 35px);
  border-radius: 3px;
  font-size: 12px;
  outline: none;

  &:focus {
    border-color: ${(props: InputProps) =>
      props.valid || !props.validated ? '#2CEAA3' : '#E85D75'};
  }
`;
