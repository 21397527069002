import React from 'react';
import {
  Container,
  FramePreview,
  RadioOuter,
  RadioInner,
} from './frame-option.styles';
import {theme} from '../../../theme';
import {StandardText} from '../styled/standard-text';

interface Props {
  name: string;
  color: keyof typeof theme.colors;
  selected: boolean;
  priceString?: string;
  onSelect: () => void;
}

const FrameOption = (props: Props) => (
  <Container onClick={props.onSelect}>
    <FramePreview color={props.color} />
    <RadioOuter selected={props.selected}>
      <RadioInner selected={props.selected} />
    </RadioOuter>
    <StandardText opacity={1}>{props.name}</StandardText>
    {props.priceString && (
      <StandardText opacity={1}>{props.priceString}</StandardText>
    )}
  </Container>
);

export {FrameOption};
