import React from 'react';
import {Input as ChakraInput} from '@chakra-ui/core';
import styled from 'styled-components';
import {InputContainer} from './input.styles';
import {theme} from '../../../theme';

interface Props {
  value: string;
  name: string;
  label?: string;
  type?: string;
  placeholder: string;
  explainerText?: string;
  onChange: (event: React.FormEvent<HTMLElement>) => void;
  validate?: (str: string) => boolean;
}

const StyledChakraInput = styled(ChakraInput)`
  font-size: 14px !important;
  height: 32px !important;

  ::placeholder {
    color: white;
    opacity: 0.5;
  }
`;

const Input = ({value, placeholder, onChange, validate, name, type}: Props) => {
  return (
    <InputContainer>
      <StyledChakraInput
        isInvalid={!!validate && !validate(value)}
        placeholder={placeholder}
        backgroundColor={theme.colors.backgroundTwo}
        type={type || 'text'}
        name={name}
        color={theme.colors.white}
        fontFamily="poppins"
        value={value}
        borderColor="transparent"
        borderWidth={0}
        focusBorderColor="transparent"
        onChange={onChange}
      />
    </InputContainer>
  );
};

export {Input};
