import styled from 'styled-components';
import {theme} from '../../../theme';

const DropDownContainer = styled.div`
  position: relative;
`;

const Input = styled.input`
  outline: none;
  border: none;
  background: transparent;
  color: white;
  font-family: poppins-light;
  width: calc(100% - 24px);
  ::placeholder {
    color: white;
  }
  &:focus {
    ::placeholder {
      color: white;
      opacity: 0.5;
    }
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    cursor: pointer;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const InputContainer = styled.div`
  background-color: ${theme.colors.backgroundTwo};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border-radius: 5px;
  padding: 0 12px;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
`;

const ChevronContainer = styled.div<{rotate: boolean}>`
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  transform: rotate(${(props) => (!props.rotate ? 180 : 0)}deg) translateY(-8px);
  transition: all 0.3s ease;
  left: 4px;
  position: relative;
`;

const Option = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.inactive};
  }
`;

const OptionContainer = styled.div`
  padding-top: 10px;
  position: absolute;
  top: 27px;
  width: 100%;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  background: ${theme.colors.backgroundTwo};
  border-radius: 0 0 5px 5px;
`;

const OptionText = styled.p`
  color: white;
  margin: 0;
  font-family: poppins-light;
  font-size: 12px;
`;

export {
  DropDownContainer,
  Input,
  InputContainer,
  ChevronContainer,
  OptionText,
  Option,
  OptionContainer,
};
