import styled from 'styled-components';
import {theme} from '../../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 80px;
  margin-top: 16px;
  &:active {
    opacity: 0.8;
  }
`;

export const FramePreview = styled.div<{color: keyof typeof theme.colors}>`
  width: 40px;
  height: 16px;
  border-radius: 2px;
  background: ${(props) => theme.colors[props.color] as string};
`;

export const RadioOuter = styled.div<{selected: boolean}>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? theme.colors.buttonBlue : theme.colors.inactive};
`;

export const RadioInner = styled.div<{selected: boolean}>`
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.selected ? theme.colors.white : theme.colors.inactive};
`;
