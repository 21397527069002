import styled from 'styled-components';
import {theme} from '../../theme';

interface CanvasContainerProps {
  display: boolean;
  isMobile: boolean;
}

export const CanvasContainer = styled.section<CanvasContainerProps>`
  background: ${theme.colors.backgroundThree};
  align-items: center;
  height: 100%;
  max-height: ${(props) => (props.isMobile ? 'auto' : '100vh')};
  justify-content: center;
  display: flex;
`;

export const Canvas = styled.canvas`
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
`;

export const PlanetsContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  background: white;
  flex-direction: column;
  flex: 1;
  min-height: ${(props) => (props.isMobile ? 'auto' : '100vh')};
  margin: ${(props) => (props.isMobile ? '16px 0' : '0')};
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  background: white;
`;

export const Container = styled.div`
  padding: 10px;
`;
