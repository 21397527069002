import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-top: 16px;
  display: inline-grid;
  grid-gap: 8px;
  grid-template-columns: calc(28% - 8px / 3) calc(45% - 8px / 3) calc(
      27% - 8px / 3
    );

  max-width: 320px;

  width: 100%;
`;
