import React, {useState, useMemo, useEffect} from 'react';
import {DropDown} from '../dropdown';

import {InputContainer} from './date-input.styles';

interface Props {
  onChange: (date: Date) => void;
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDay = new Date().getDate();

const yearOptions = [...Array(141)].map((_, i) => ({
  value: 2040 - i,
  label: 2040 - i,
}));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month) => ({
  value: month,
  label: month,
}));

const DateInput = ({onChange}: Props) => {
  const [year, setYear] = useState<number | string | null>(null);
  const [month, setMonth] = useState<number | string | null>(null);
  const [day, setDay] = useState<number | string | null>(null);

  const date = useMemo(
    () =>
      new Date(
        Number(year || currentYear),
        month ? Number(months.indexOf(`${month}`)) : currentMonth,
        Number(day) || currentDay,
      ),
    [day, month, year],
  );

  useEffect(() => {
    if (date) {
      onChange(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const dayOptions = [
    ...Array(
      new Date(
        Number(year || currentYear),
        month ? Number(months.indexOf(`${month}`)) + 1 : 0,
        0,
      ).getDate(),
    ),
  ].map((_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  useEffect(() => {
    if (day && day > dayOptions[dayOptions.length - 1].value) {
      setDay(dayOptions[dayOptions.length - 1].value);
    }
  }, [day, dayOptions]);

  return (
    <InputContainer>
      <DropDown
        type="number"
        value={year}
        onChange={setYear}
        options={yearOptions}
        placeholder="Year"
      />
      <DropDown
        type="string"
        value={month}
        onChange={setMonth}
        options={monthOptions}
        placeholder="Month"
      />
      <DropDown
        type="number"
        value={day}
        onChange={setDay}
        options={dayOptions}
        placeholder="Day"
      />
    </InputContainer>
  );
};

export {DateInput};
