import styled from 'styled-components';
import {theme} from '../../../theme';

interface Props {
  fontSize?: number;
  opacity?: number;
  margin?: string;
  color?: string;
}

const StandardText = styled.p`
  font-size: ${(props: Props) => props.fontSize || 14}px;
  line-height: ${(props: Props) => (props.fontSize || 16) + 1}px;
  margin: ${(props: Props) => props.margin || '3px 0'};
  color: ${(props: Props) => props.color || theme.colors.white};
  font-family: poppins-light;
  opacity: ${(props: Props) => props.opacity || 0.8};
`;

export {StandardText};
