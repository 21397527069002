import styled from 'styled-components';
import {theme} from '../../theme';

interface ContainerProps {
  formWidth: number;
}

export const FormContainer = styled.section`
  padding: 10px 0px 10px 0px;
  width: ${(props: ContainerProps) => props.formWidth}px;
  max-width: ${(props: ContainerProps) => props.formWidth}px;
  min-width: ${(props: ContainerProps) => props.formWidth}px;
  background: ${theme.colors.blackBlue};
  min-height: 100vh;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow-y: auto;
`;

export const DateFormatContainer = styled.div`
  padding-top: 32px;
`;
export const LogoContainer = styled.div<{src: string}>`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #f1f1f1;
  border-radius: 1px solid #e3e3e3;
  margin-bottom: 16px;  
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
 
  background-size: cover;
  background-image: url("${(props) => props.src}");
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`;

export const ButtonContainer = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

export const PlanetsContainer = styled.div`
  padding: 24px 0;
`;

export const FormItemsContainer = styled.div``;
export const Price = styled.h2`
  color: white;
  margin: 0 0 0 0px;
  font-family: poppins;
  font-size: 24px;
`;
export const Subtotal = styled.h2`
  color: white;
  margin: 0 16px 0 8px;
  font-family: poppins-light;
  font-size: 14px;
`;
