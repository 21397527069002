import styled from 'styled-components';
import {theme} from '../../../theme';

const ChevronContainer = styled.div<{rotate: boolean}>`
  height: 24px;
  width: 24px;
  transform: rotate(${(props) => (!props.rotate ? 180 : 0)}deg) translateY(-3px);
  transform-origin: 50% 50%;
  transition: ${theme.transitions};
`;

const Title = styled.h4<{selected: boolean}>`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: ${theme.colors.white};
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  font-family: poppins;
  transition: ${theme.transitions};
  margin: 0;
`;

const Container = styled.div<{selected: boolean}>`
  width: 100%;
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.accordion};
  background-color: ${(props) =>
    props.selected ? theme.colors.accordion : 'transparent'};
`;

// eslint-disable-next-line
const ChildrenContainer = styled.div<{
  height: number;
  selected: boolean;
  fullyOpen: boolean;
}>`
  width: 100%;
  padding: 0 30px;
  max-height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  transition: ${theme.transitions};
  overflow: ${(props) => (props.fullyOpen ? 'visible' : 'hidden')};
  background-color: ${(props) =>
    props.selected ? theme.colors.accordion : 'transparent'};
`;

export {ChevronContainer, Title, Container, ChildrenContainer};
