export const CANVAS_PADDING = 0;
export const FORM_WIDTH = 452;
export const MARKETING_EMAIL_ENABLED = false;

export const DATE_FORMATS = {
  en: [
    {label: 'Words', value: 'Do MMMM YYYY'},
    {label: 'Slashes', value: 'DD/MM/YYYY'},
    {label: 'Dots', value: 'DD.MM.YYYY'},
    {label: 'Dashes', value: 'DD-MM-YYYY'},
  ],
  us: [
    {label: 'Words', value: 'MMMM Do YYYY'},
    {label: 'Slashes', value: 'MM/DD/YYYY'},
    {label: 'Dots', value: 'MM.DD.YYYY'},
    {label: 'Dashes', value: 'MM-DD-YYYY'},
  ],
};

export enum ImageStyle {
  Portrait = 'Portrait',
  Square = 'Square',
}

export const BASE_PRICE = 37;

export const SIZE_OPTIONS = [
  {
    dimensions: {width: 30, height: 30},
    dimensionsInches: {width: 12, height: 12},
    orientation: ImageStyle.Square,
    productId: '5603340943521',
    variantIds: {
      black: '36566878617761',
      natural: '36566878683297',
      white: '36566878650529',
      none: '36566878584993',
    },
  },
  {
    dimensions: {width: 30, height: 40},
    dimensionsInches: {width: 12, height: 16},
    orientation: ImageStyle.Portrait,
    productId: '5569793556641',
    variantIds: {
      black: '36566520496289',
      natural: '36566520463521',
      white: '36566520529057',
      none: '36566520561825',
    },
  },
  {
    dimensions: {width: 50, height: 50},
    dimensionsInches: {width: 20, height: 20},
    productId: '5752910545057',
    orientation: ImageStyle.Square,
    variantIds: {
      black: '36566838050977',
      natural: '36566837952673',
      white: '36566837985441',
      none: '36566837919905',
    },
  },
  {
    dimensions: {width: 50, height: 70},
    dimensionsInches: {width: 20, height: 28},
    productId: '5603342712993',
    orientation: ImageStyle.Portrait,
    variantIds: {
      black: '36566898737313',
      natural: '36566898802849',
      white: '36566898770081',
      none: '36566898835617',
    },
  },
].map((size) => ({
  ...size,
  dimensionString: `${size.dimensionsInches.width}x${size.dimensionsInches.height}”\n(${size.dimensions.width}x${size.dimensions.height}cm)`,
}));

export const FRAME_OPTIONS = [
  {
    color: 'inactive' as const,
    id: 'none' as const,
    name: 'No frame',
  },
  {
    color: 'black' as const,
    id: 'black' as const,
    name: 'Black',
    price: 12.5,
  },
  {
    color: 'natural' as const,
    id: 'natural' as const,
    name: 'Natural',
    price: 12.5,
  },
  {
    color: 'white' as const,
    id: 'white' as const,
    name: 'White',
    price: 12.5,
  },
];
