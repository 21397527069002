import styled from 'styled-components';
import {theme} from '../../../theme';

export const StyledButton = styled.button`
  font-family: poppins
  font-size: 14px !important;
  font-weight: 700 !important;
  border: none;
  letter-spacing: 0.1em !important;
  background-color: ${theme.colors.buttonBlue};
  width: 100%;
  padding: 15px 30px !important;
  height: auto !important;
  border-radius: 10px;
  color: white;
`;
