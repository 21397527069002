import React, {useRef, useState, useEffect, useContext} from 'react';

import {Icon} from '@chakra-ui/core';
import {
  ChevronContainer,
  Title,
  Container,
  ChildrenContainer,
} from './accordion.styles';
import {AccordionContext} from '../../form';

interface AccordionProps {
  children: React.ReactNode;
  title: string;
  initialOpen?: boolean;
}

const Accordion = ({children, title, initialOpen}: AccordionProps) => {
  const [accordionId] = useState(Math.random());
  const [fullyOpen, setFullyOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const {openAccordionId, setOpenAccordionId} = useContext(AccordionContext);

  const open = openAccordionId === accordionId;

  useEffect(() => {
    if (initialOpen) {
      setOpenAccordionId(accordionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(() => setFullyOpen(true), 300);
    } else {
      setFullyOpen(false);
    }
  }, [open]);

  const childrenNode = (
    <div style={{paddingBottom: 24}} ref={ref}>
      {children}
    </div>
  );

  return (
    <div>
      <Container
        selected={open}
        onClick={() => setOpenAccordionId(open ? null : accordionId)}
      >
        <Title selected={open}>{title}</Title>
        <ChevronContainer rotate={open}>
          <Icon size="24px" name="chevron-up" color="white" />
        </ChevronContainer>
      </Container>
      <ChildrenContainer
        fullyOpen={fullyOpen}
        selected={open}
        height={(open && ref.current && ref.current.clientHeight) || 0}
      >
        {childrenNode}
      </ChildrenContainer>
    </div>
  );
};

export {Accordion};
