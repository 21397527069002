import styled from 'styled-components';
import {theme} from '../../../theme';

const diffX = (frameWidth: number) =>
  ((2 * frameWidth ** 2) ** 0.5 - frameWidth) / 2 + 3 ** 0.5;

// eslint-disable-next-line
export const FrameContainer = styled.div<{
  active: boolean;
}>`
  margin: ${(props) => (props.active ? 38 : 0)}px;
  border-radius: ${(props) => (props.active ? 3 : 0)}px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.active ? `4px 8px 6px 0px rgba(0,0,0,0.25)` : 'none'};
`;

// eslint-disable-next-line
export const InnerShadow = styled.div<{
  active: boolean;
  color: keyof typeof theme.colors;
  height: number;
  width: number;
  padding: number;
}>`
  background: ${(props) => theme.colors[props.color] as string};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  padding: ${(props) => props.padding}px;
  position: relative;
  box-shadow: ${(props) =>
    props.active ? `inset 0 0 4px 0px rgba(0,0,0,0.25)` : 'none'};
`;

export const TopRightLine = styled.div<{frameWidth: number}>`
  width: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  height: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  border-right: 1px solid ${theme.colors.inactive};
  -webkit-transform: translateY(-50%)
    translateX(-${({frameWidth}) => diffX(frameWidth)}px) rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
`;

export const TopLeftLine = styled.div<{frameWidth: number}>`
  width: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  height: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  border-bottom: 1px solid ${theme.colors.inactive};
  -webkit-transform: translateY(-50%)
    translateX(${({frameWidth}) => diffX(frameWidth)}px) rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const BottomLeftLine = styled.div<{frameWidth: number}>`
  width: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  height: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  border-left: 1px solid ${theme.colors.inactive};
  -webkit-transform: translateY(50%)
    translateX(${({frameWidth}) => diffX(frameWidth)}px) rotate(45deg);
  position: absolute;
  bottom: 0;
  left: 1px;
  z-index: 0;
`;

export const BottomRightLine = styled.div<{frameWidth: number}>`
  width: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  height: ${({frameWidth}) => 2 ** 0.5 * frameWidth + 3}px;
  border-top: 1px solid ${theme.colors.inactive};
  -webkit-transform: translateY(50%)
    translateX(-${({frameWidth}) => diffX(frameWidth)}px) rotate(45deg);
  position: absolute;
  bottom: 0;
  right: 1px;
  z-index: 0;
`;
