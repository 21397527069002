import {useEffect, useState} from 'react';
import {useFormik, useFormikContext} from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import Client from 'shopify-buy';

import {DATE_FORMATS, SIZE_OPTIONS, FRAME_OPTIONS} from '../../constants';

export enum Properties {
  SpecialDate = 'Special Date',
  HidePluto = 'Hide Pluto',
  OrbitLines = 'Orbit Lines',
  DateStyle = 'Date Style',
  LineOne = 'Line One',
  LineTwo = 'Line Two',
  Email = 'Email',
  Marketing = 'Marketing',
  Locale = 'Locale',
  Size = 'Size',
  Frame = 'Frame',
  Width = 'Width',
  Height = 'Height',
  FrameColour = 'Frame Colour',
}

const DEFAULT_LOCALE = 'us';

type PriceData = Record<string, {text: string; number: number}>;

const client = Client.buildClient({
  domain: 'the-planets-aligned-uk.myshopify.com',
  storefrontAccessToken: '768b24588d77caabf4a9588c9c357da3',
});

export const useFormState = () => {
  const [priceData, setPriceData] = useState<PriceData>({});
  const form = useFormik({
    onSubmit: (values) => {
      // Properties passed to shopify and into the webhook
      const properties = {
        [Properties.HidePluto]: values[Properties.HidePluto],
        [Properties.SpecialDate]: moment(values[Properties.SpecialDate]).format(
          'YYYY-MM-DD',
        ),
        [Properties.DateStyle]: values[Properties.DateStyle],
        [Properties.LineOne]: values[Properties.LineOne],
        [Properties.LineTwo]: values[Properties.LineTwo],
        [Properties.OrbitLines]: values[Properties.OrbitLines],
        [Properties.Width]: values[Properties.Size].dimensions.width,
        [Properties.Height]: values[Properties.Size].dimensions.height,
        ...(values[Properties.Frame].price
          ? {[Properties.FrameColour]: values[Properties.Frame].color}
          : {}),
      };

      const params = Object.keys(properties).reduce(
        (cur, next) =>
          // eslint-disable-next-line no-undef
          `${cur}&properties[${next}]=${window.encodeURIComponent(
            (properties as any)[next],
          )}`,
        '',
      );

      // eslint-disable-next-line no-undef
      window.location.href = `https://planetsintime.com/cart/add?id[]=${
        values[Properties.Size].variantIds[values[Properties.Frame].id]
      }&quantity=1&${params}`;
    },
    validationSchema: yup.object().shape({
      [Properties.SpecialDate]: yup.date(),
      [Properties.HidePluto]: yup.bool(),
      [Properties.OrbitLines]: yup.bool(),
      [Properties.DateStyle]: yup.string(),
      [Properties.LineOne]: yup.string(),
      [Properties.LineTwo]: yup.string(),
      [Properties.Email]: yup.string().nullable(),
      [Properties.Marketing]: yup.bool(),
      [Properties.Locale]: yup.string(),
    }),
    initialValues: {
      [Properties.SpecialDate]: new Date(),
      [Properties.HidePluto]: false,
      [Properties.OrbitLines]: true,
      [Properties.DateStyle]: DATE_FORMATS[DEFAULT_LOCALE][0].value,
      [Properties.LineOne]: '',
      [Properties.LineTwo]: '',
      [Properties.Email]: '',
      [Properties.Marketing]: false,
      [Properties.Locale]: DEFAULT_LOCALE as keyof typeof DATE_FORMATS,
      [Properties.Size]: SIZE_OPTIONS[0],
      [Properties.Frame]: FRAME_OPTIONS[0],
    },
  });

  useEffect(() => {
    const getData = async () => {
      const variantPriceData = await Promise.all(
        SIZE_OPTIONS.map(async (option) => {
          const product = await client.product.fetch(
            // eslint-disable-next-line no-undef
            btoa(`gid://shopify/Product/${option.productId}`),
          );

          const {variants} = product;

          return variants;
        }),
      );

      setPriceData(
        variantPriceData.reduce(
          (sum, data) => ({
            ...sum,
            ...data.reduce(
              (variantSum, variant) => ({
                ...variantSum,
                // eslint-disable-next-line no-undef
                [atob(`${variant.id}`).split(
                  'gid://shopify/ProductVariant/',
                )[1]]: {
                  text: variant.price,
                  number: parseFloat(variant.price),
                },
              }),
              {} as PriceData,
            ),
          }),
          {} as PriceData,
        ),
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {form, priceData};
};

export type FormStateType = ReturnType<typeof useFormState>;

export const useFormStateContext = () =>
  useFormikContext<FormStateType['form']['values']>();
