import React, {createContext, useState, useMemo} from 'react';
import moment from 'moment';
import {overwriteOrdinalPlaceholder} from '@pit/image-generator';
import styled from 'styled-components';
import {currencyConverter} from '../../currency-converter';
import {
  FormContainer,
  DateFormatContainer,
  LogoContainer,
  FormItemsContainer,
  CenteredContainer,
  ButtonContainer,
  PlanetsContainer,
  Price,
  Subtotal,
} from './form.styles';
import {DateInput} from '../common/date-input';
import {Toggle} from '../common/toggle';
import {Input} from '../common/input';
import {StyledButton} from '../common/styled/styled-button';
import {StandardText} from '../common/styled/standard-text';
import {DATE_FORMATS, FRAME_OPTIONS, SIZE_OPTIONS} from '../../constants';
import {FormStateType, Properties} from './state';
import {ScreenSizing} from '../../types';
import LOGO from '../../logo.png';
import {Accordion} from '../common/accordion';
import {DropDown} from '../common/dropdown';
import {Planets} from '../planets';
import {ImageSize} from '../common/image-size';
import {Inline} from '../common/styled/inline';
import {FrameOption} from '../common/frame-option';

export const StyledText = styled.p<{margin?: string}>`
  font-family: 'News Cycle', sans-serif;
  font-size: 13px !important;
  line-height: 14px;
  font-weight: 500 !important;
  margin: ${(props) => props.margin || '2px 0px'};
`;

const getParameterByName = (name: string) => {
  // eslint-disable-next-line no-undef
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

type AccordionIdType = string | number | null;

const AccordionContext = createContext<{
  openAccordionId: AccordionIdType;
  setOpenAccordionId: (id: AccordionIdType) => void;
}>({
  openAccordionId: null,
  setOpenAccordionId: () => null,
});

const Form = ({
  form,
  screenSizing,
  toggleReview,
  isMobile,
  priceData,
}: {
  form: FormStateType['form'];
  priceData: FormStateType['priceData'];
  screenSizing: ScreenSizing;
  toggleReview: Function;
  isMobile: boolean;
}) => {
  const currency = useMemo(() => {
    const cur = getParameterByName('currency');
    return cur && cur.length === 3 ? cur : 'GBP';
  }, []);
  const [openAccordionId, setOpenAccordionId] = useState<AccordionIdType>(null);
  const options = DATE_FORMATS[form.values[Properties.Locale]].map(
    (format) => ({
      label: overwriteOrdinalPlaceholder(
        `${format.label} - ${moment(
          form.values[Properties.SpecialDate] || new Date(),
        ).format(format.value)}`,
      ),
      value: format.value,
    }),
  );

  const price =
    priceData[
      form.values[Properties.Size].variantIds[form.values[Properties.Frame].id]
    ];

  return (
    <AccordionContext.Provider value={{openAccordionId, setOpenAccordionId}}>
      <FormContainer
        formWidth={
          screenSizing.isMobile
            ? screenSizing.innerWidth
            : screenSizing.formWidth
        }
      >
        <FormItemsContainer>
          <CenteredContainer>
            <LogoContainer src={LOGO} />
            <StandardText fontSize={32}>Planets In Time</StandardText>
          </CenteredContainer>
          <Accordion initialOpen title="Choose your print size...">
            <Inline>
              {SIZE_OPTIONS.map((size, index) => (
                <ImageSize
                  selected={
                    form.values[Properties.Size].dimensionString ===
                    size.dimensionString
                  }
                  key={size.productId}
                  price={(() => {
                    const priceVariant = priceData[size.variantIds.none];
                    const basePricevariant =
                      priceData[SIZE_OPTIONS[0].variantIds.none];
                    if (priceVariant) {
                      const priceConverted = currencyConverter.convert(
                        priceVariant.number,
                        currency,
                      );
                      const basePriceConverted = currencyConverter.convert(
                        basePricevariant.number,
                        currency,
                      );
                      return priceConverted - basePriceConverted
                        ? `+${currencyConverter.format(
                            priceConverted - basePriceConverted,
                            currency,
                          )}`
                        : `${currencyConverter.format(
                            priceConverted,
                            currency,
                          )}`;
                    }
                    return '';
                  })()}
                  dimensionsInches={size.dimensionsInches}
                  dimensions={size.dimensions}
                  onSelect={() => form.setFieldValue(Properties.Size, size)}
                  noMargin={index === SIZE_OPTIONS.length - 1}
                />
              ))}
            </Inline>
          </Accordion>
          <Accordion title="Choose your special date...">
            <DateInput
              onChange={(date) =>
                form.setFieldValue(Properties.SpecialDate, date)
              }
            />
          </Accordion>
          <Accordion title="Format to your liking...">
            <Toggle
              text="US Date Format"
              name={Properties.Locale}
              checked={form.values[Properties.Locale] === 'us'}
              onChange={(e) => {
                const newLocale = e.target.checked ? 'us' : 'en';
                const newFormat =
                  DATE_FORMATS[newLocale][
                    DATE_FORMATS[form.values[Properties.Locale]]
                      .map(({value}) => value)
                      .indexOf(form.values[Properties.DateStyle])
                  ];

                if (newFormat)
                  form.setFieldValue(Properties.DateStyle, newFormat.value);
                form.setFieldValue(Properties.Locale, newLocale);
              }}
            />
            <DateFormatContainer>
              <DropDown
                options={options}
                type="string"
                placeholder="Choose your date format"
                initialValue="Choose your date format"
                value={form.values[Properties.DateStyle]}
                onChange={(value) => {
                  form.setFieldValue(Properties.DateStyle, value);
                }}
              />
            </DateFormatContainer>

            <Toggle
              name={Properties.HidePluto}
              checked={form.values[Properties.HidePluto]}
              text="Hide pluto from the image?"
              onChange={(e) =>
                form.setFieldValue(Properties.HidePluto, e.target.checked)
              }
            />
            <Toggle
              name={Properties.OrbitLines}
              checked={form.values[Properties.OrbitLines]}
              text="Show orbit lines?"
              onChange={(e) =>
                form.setFieldValue(Properties.OrbitLines, e.target.checked)
              }
            />
          </Accordion>
          <Accordion title="Add a personalized message...">
            <Input
              name={Properties.LineOne}
              value={form.values[Properties.LineOne]}
              placeholder="Top line (blank by default)"
              onChange={form.handleChange}
            />
            <Input
              name={Properties.LineTwo}
              value={form.values[Properties.LineTwo]}
              placeholder="Bottom line"
              onChange={form.handleChange}
            />
          </Accordion>
          <Accordion title="Choose your frame colour...">
            <Inline>
              {FRAME_OPTIONS.map((option) => (
                <FrameOption
                  onSelect={() => form.setFieldValue(Properties.Frame, option)}
                  key={option.color}
                  color={option.color}
                  name={option.name}
                  selected={
                    form.values[Properties.Frame].color === option.color
                  }
                  priceString={(() => {
                    const selectedFrameVariant =
                      priceData &&
                      priceData[
                        form.values[Properties.Size].variantIds[
                          form.values[Properties.Frame].id
                        ]
                      ];
                    return option.id !== 'none' && selectedFrameVariant
                      ? `+${currencyConverter.convertWithFormat(
                          priceData[
                            form.values[Properties.Size].variantIds[option.id]
                          ].number -
                            priceData[
                              form.values[Properties.Size].variantIds.none
                            ].number,
                          currency,
                        )}`
                      : undefined;
                  })()}
                />
              ))}
            </Inline>
          </Accordion>
          {isMobile && (
            <PlanetsContainer>
              <Planets
                screenSizing={screenSizing}
                toggleReview={toggleReview}
                isMobile
              />
            </PlanetsContainer>
          )}
        </FormItemsContainer>

        <ButtonContainer>
          <Price>
            {price &&
              currencyConverter.convertWithFormat(price.number, currency)}
          </Price>
          <Subtotal>Subtotal</Subtotal>
          <StyledButton disabled={!form.isValid} onClick={form.submitForm}>
            Add to Cart
          </StyledButton>
        </ButtonContainer>
      </FormContainer>
    </AccordionContext.Provider>
  );
};

export {Form, AccordionContext};
