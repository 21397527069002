/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import {ThemeProvider} from '@chakra-ui/core';
import './global-styles.css';
import {FormikProvider} from 'formik';
import {Planets} from './components/planets/index';
import {Form} from './components/form/index';
import {
  GlobalStyle,
  Container,
  PreloadAkwproscFont,
  PreloadPoppinsFont,
} from './App.styles';
import {theme} from './theme';
import {FORM_WIDTH, CANVAS_PADDING} from './constants';
import {useFormState, Properties} from './components/form/state';
import {ScreenSizing} from './types';

const isMobile = () => {
  return window.innerWidth < 1024;
};

const getScreenSizing = () => {
  const formWidth = isMobile() ? window.innerWidth - 60 : FORM_WIDTH;
  const canvasSize = Math.min(
    isMobile() ? window.innerWidth : window.innerHeight - 2 * CANVAS_PADDING,
    isMobile()
      ? window.innerWidth
      : window.innerWidth - formWidth - 2 * CANVAS_PADDING,
    isMobile() ? window.innerWidth : window.innerHeight,
  );
  return {
    isMobile: isMobile(),
    innerWidth: window.innerWidth,
    maxWidth: isMobile()
      ? window.innerWidth
      : window.innerWidth - formWidth - 2 * CANVAS_PADDING,
    maxHeight: isMobile() ? window.innerWidth * 1.5 : window.innerHeight,
    formWidth,
    canvasSize,
  };
};

const App: React.FC = () => {
  const [screenSizing, setScreenSizing] = useState<ScreenSizing>(
    getScreenSizing(),
  );

  const [review, setReview] = useState(false);

  const toggleReview = () => setReview(!review);

  const {form, priceData} = useFormState();

  const resize = () => {
    if (!isMobile()) {
      setScreenSizing(getScreenSizing());
    }
  };

  useEffect(() => {
    (document as any).fonts.ready.then(() => {
      if (!form.values[Properties.SpecialDate])
        form.setFieldValue(Properties.SpecialDate, new Date());
    });

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [form]);

  return (
    <>
      <GlobalStyle />
      <PreloadAkwproscFont />
      <PreloadPoppinsFont />
      <ThemeProvider theme={theme}>
        <FormikProvider value={form}>
          {isMobile() === true ? (
            <Container>
              <Form
                screenSizing={screenSizing}
                form={form}
                toggleReview={toggleReview}
                isMobile={isMobile()}
                priceData={priceData}
              />
            </Container>
          ) : (
            <Container>
              <Form
                screenSizing={screenSizing}
                form={form}
                toggleReview={toggleReview}
                isMobile={isMobile()}
                priceData={priceData}
              />
              <Planets
                screenSizing={screenSizing}
                toggleReview={toggleReview}
                isMobile={isMobile()}
              />
            </Container>
          )}
        </FormikProvider>
      </ThemeProvider>
    </>
  );
};

export {App};
